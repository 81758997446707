import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips'
import { MatAutocompleteModule, MatBadgeModule, MatTreeModule } from '@angular/material';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { environment } from 'src/environments/environment';
import { GlobalApiService } from './services/global/global-api/global-api.service';
import { GlobalSrvConstantsService } from './services/global/global-constants/global-srv-constants.service';
import { SrvLoaderService } from './services/global/global-shared/global-srv-loader/srv-loader.service';
import { UserIdleModule } from 'angular-user-idle'
import { IdleTimeOutSettings } from './Constants';
import { AppConfigService } from './services/common/app-config/app-config.service';
import { AuthInterceptor } from './authInterceptor';
import { BoGlobalDateRangeComponent } from './bo-global/bo-global-date-range/bo-global-date-range.component';
import { BoGlobalTableComponent } from './bo-global/bo-global-table/bo-global-table.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { BoConfirmationDialogComponent } from './bo-confirmation-dialog/bo-confirmation-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { ReturnsService } from './services/Returns/gstr-one.service';
import { MatStepperModule } from '@angular/material/stepper';
import { TimeoutInterceptor } from './timeoutInterceptor';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { NumberDirective } from './directives/number.directive';
import { NumberLimitDirective } from './directives/numberlimit.directive';
import { MatSearchComponent} from './mat-search/mat-search-component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

@NgModule({
  declarations: [
    LoaderComponent,
    BoGlobalTableComponent,
    BoConfirmationDialogComponent,
    BoGlobalDateRangeComponent,
    SendEmailComponent,
    NumberDirective,
    NumberLimitDirective,
    MatSearchComponent, UnauthorizedComponent],
  imports: [
    UserIdleModule.forRoot({ idle: IdleTimeOutSettings.IdleTime, timeout: IdleTimeOutSettings.TimeOutAfterIdleTime }),
    CommonModule,
    HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.resourceURL, environment.reportServiceURL,environment.gstrServiceUrl, environment.returnServiceUrl],
                sendAccessToken: true
            }
        }),
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatGridListModule,
        ReactiveFormsModule,
        SelectAutocompleteModule,
        FormsModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatExpansionModule,
        MatTabsModule,
        SatDatepickerModule,
        SatNativeDateModule,
        MatDatepickerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTreeModule,
        MatStepperModule,
        EcoFabSpeedDialModule,
        MatBadgeModule,
        MatTableModule
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    GlobalApiService,
    SrvLoaderService,
    ReturnsService,
    MatDatepickerModule,
    GlobalSrvConstantsService,
    AppConfigService,
    {
        provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: {
            separatorKeyCodes: [ENTER, SEMICOLON]
        }
    },
],
exports:[
  LoaderComponent,
  BoGlobalTableComponent,
  BoConfirmationDialogComponent,
  BoGlobalDateRangeComponent,
  SendEmailComponent,
  MatSearchComponent,
  LayoutModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatGridListModule,
  ReactiveFormsModule,
  SelectAutocompleteModule,
  FormsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatExpansionModule,
  MatTabsModule,
  SatDatepickerModule,
  SatNativeDateModule,
  MatDatepickerModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatStepperModule,
  MatBadgeModule,
  NumberDirective,
  NumberLimitDirective,
  MatTableModule
],
entryComponents:[
  SendEmailComponent,
  BoConfirmationDialogComponent,
  MatSearchComponent
 
]
})
export class SharedModuleModule { }
