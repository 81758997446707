import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatChipInputEvent } from '@angular/material';
import { EmailDetails, MasterEmailType, eInvoiceCopyEmailContent, emailAddressType, eWayBillCopyEmailContent } from '../../shared/models/common/send-email/send-email';
import { CommonService } from '../../shared/services/common/common.service';
import { getEInvoiceCopyFileName, isValidEmailIDs, isStringNullOrEmpty, getEwayBillCopyFileName } from '../../shared/helpers/common-helper';
import { GlobalSrvConstantsService } from '../../shared/services/global/global-constants/global-srv-constants.service';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { AlertService } from '../../shared/services/common/alert/alert.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  isToDisabled: boolean = false;
  isCCDisabled: boolean = false;
  isBCCDisabled: boolean = true;
  enableBCCControl: boolean  = false;

  emailsTo = [];
  emailsCC = [];
  emailsBCC = [];
  content: string;
  subject: string;
  referenceData: string;
  emailType: number;
  attachmentsPath: string;
  isHtmlContent: boolean;

  documentNumber: string;
  hasAttachments: boolean;
  attachmentsName: string;
  isStaticHTMLContent: boolean = false;
invoiceFileId:number;
xmlFileId:number;
outwardDocumentHeaderId:number;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  sendEmailDialogForm: FormGroup;
  isEmailSendBtnDisabled: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    protected ref: MatDialogRef<any>,
    private commonService: CommonService,
    private alertService: AlertService,
    private datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.sendEmailDataInit(data);
    this.sendEmailFormInit();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if(this.enableBCCControl) {
      this.isBCCDisabled = false;
    }

  }

  sendEmailFormInit() {
    this.sendEmailDialogForm = this.formBuilder.group({
      toEmailAddresses: [''],
      ccEmailAddresses: [''],
      bccEmailAddresses: [''],
      subject: this.subject,
      content: this.content,
    });
  }

  sendEmailDataInit(data: any) {
    const toEmails = data.toEmailAddresses;
    const ccEmails = data.ccEmailAddresses;
    const bccEmails = data.bccEmailAddresses;
    this.invoiceFileId = data.invoiceFileId;
    this.xmlFileId = data.xmlFileId;
    this.outwardDocumentHeaderId = data.outwardDocumentHeaderId;
    this.referenceData = (data.referenceData).toString();
    this.emailsTo = (toEmails && toEmails.trim().length) ? toEmails.split(',') : [];
    this.emailsCC = (ccEmails && ccEmails.trim().length) ? ccEmails.split(',') : [];
    this.emailsBCC = (bccEmails && bccEmails.trim().length) ? bccEmails.split(',') : [];
    this.subject = data.subject;
    this.content = data.content;
    this.emailType = data.emailType;
    this.attachmentsPath = data.attachmentsPath;
    this.isHtmlContent = data.isHtmlContent;
    this.hasAttachments = data.hasAttachments;
    this.enableBCCControl = data.enableBCCControl;

    switch (this.emailType) {
      case MasterEmailType.eInvoiceCopy:
        const desiredDateFormat='dd-MM-yyyy';
        const CurrDateFromat='DD/MM/YYYY HH:mm';
        const parsedDate =moment(data.documentDate,CurrDateFromat);    
        const formattedDate = this.datepipe.transform(parsedDate, desiredDateFormat);    
        this.content = this.content.replace(eInvoiceCopyEmailContent.documentDate, formattedDate);

        this.content = this.content.replace(eInvoiceCopyEmailContent.documentNumber, data.documentNumber);
       // this.content = this.content.replace(eInvoiceCopyEmailContent.documentDate, data.documentDate);
        this.content = this.content.replace(eInvoiceCopyEmailContent.entityLegalName, data.entityLegalName);
        this.content = this.content.replace(eInvoiceCopyEmailContent.customerLegalName, data.customerLegalName);
        this.content = this.content.replace(eInvoiceCopyEmailContent.uid, data.irn);
        this.content = this.content.replace(eInvoiceCopyEmailContent.buyerName, data.customerLegalName);
        this.attachmentsName = getEInvoiceCopyFileName(data.documentNumber);
        this.isStaticHTMLContent = true;
        break;
      case MasterEmailType.EwayBillCopy:
        this.content = this.content.replace(eWayBillCopyEmailContent.documentNumber, data.documentNumber);
        this.attachmentsName = getEwayBillCopyFileName(this.referenceData);
        this.isStaticHTMLContent = true;
        break;
      default:
        break;
    }

  }

  sendEmail() {

    this.isEmailSendBtnDisabled = true;
    const emailDetails: EmailDetails = {} as EmailDetails;

    emailDetails.toAddresses = this.emailsTo.join(',');
    emailDetails.ccemailAddresses = this.emailsCC.join(',');
    emailDetails.bccemailAddresses = this.emailsBCC.join(',');
    emailDetails.emailSubject = this.sendEmailDialogForm.controls['subject'].value;
    emailDetails.emailBody = this.sendEmailDialogForm.controls['content'].value;
    emailDetails.emailType = this.emailType;
    emailDetails.attachmentsPath = this.attachmentsPath;
    emailDetails.isHtmlcontent = this.isHtmlContent;
    emailDetails.referenceData = this.referenceData;
    emailDetails.outwardDocumentHeaderId = this.outwardDocumentHeaderId;
    emailDetails.invoiceFileId = this.invoiceFileId;
    emailDetails.xmlFileId = this.xmlFileId;
    if (this.validateSendEmailFormData(emailDetails)) {

      this.commonService.sendEmail(emailDetails).subscribe((data: any) => {
        if ('200'.localeCompare(data.status) === 0) {
          this.dismiss();
          this.alertService.showSuccessAlert('Email Sent Successfully.');
        } else {
          this.alertService.showErrorAlert('Unable to Send Email.');
        }
      },
        error => {
          let errorStatus;
          let errorMessage;
          if (error.error.message !== undefined) {
            errorStatus = error.error.status;
            errorMessage = error.error.message.substring(0, 90);
          } else {
            errorStatus = error.status;
            errorMessage = error.error;
          }
          this.alertService.showErrorAlert('Error | Status: ' + errorStatus + ' | ' + errorMessage);
        }
      );
    }
    this.isEmailSendBtnDisabled = false;

  }

  addToEmailId(event: MatChipInputEvent): void {
    this.addInputEmailId(event, emailAddressType.toEmail);
  }

  addCCEmailId(event: MatChipInputEvent): void {
    this.addInputEmailId(event, emailAddressType.ccEmail);
  }

  addBCCEmailId(event: MatChipInputEvent): void {
    this.addInputEmailId(event, emailAddressType.bccEmail);
  }

  addInputEmailId(event: MatChipInputEvent, emailType: emailAddressType) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      switch (emailType) {
        case emailAddressType.toEmail:
          this.emailsTo.push(value.trim());
          break;
        case emailAddressType.ccEmail:
          this.emailsCC.push(value.trim());
          break;
        case emailAddressType.bccEmail:
          this.emailsBCC.push(value.trim());
          break;
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeToEmailId(selectedToEmailIds) {
    this.emailsTo.splice(this.emailsTo.indexOf(selectedToEmailIds), 1);
  }

  removeCCEmailIds(selectedCCEmail) {
    this.emailsCC.splice(this.emailsCC.indexOf(selectedCCEmail), 1);
  }

  removeBCCEmailId(selectedBCCEmailIds) {
    this.emailsBCC.splice(this.emailsBCC.indexOf(selectedBCCEmailIds), 1);
  }

  validateSendEmailFormData(emailDetails: EmailDetails): boolean {

    const isValidSendEmailFormData = true;

    if (this.emailsTo.length <= 0) {
      this.alertService.showValidationAlert('To email address cannot be empty.');
      return false;
    } else {
      if (!isValidEmailIDs(this.emailsTo)) {
        this.alertService.showValidationAlert('One or more To Email Address is invalid.');
        return false;
      }
    }

    if (this.emailsCC.length > 0) {
      if (!isValidEmailIDs(this.emailsCC)) {
        this.alertService.showValidationAlert('One or more CC Email Address is invalid.');
        return false;
      }
    }

    if (this.emailsBCC.length > 0) {
      if (!isValidEmailIDs(this.emailsBCC)) {
        this.alertService.showValidationAlert('One or more BCC Email Address is invalid.');
        return false;
      }
    }

    if (isStringNullOrEmpty(emailDetails.emailSubject)) {
      this.alertService.showValidationAlert('Email Subject cannot be empty.');
      return false;
    }

    return isValidSendEmailFormData;
  }

  dismiss() {
    this.ref.close();
  }

}
