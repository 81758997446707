import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';;
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { GlobalSrvConstantsService } from '../../../../shared/services/global/global-constants/global-srv-constants.service';
import { PostInvoiceSearchService } from '../../../../shared//services/post/pst-srv-boew-inv/post-invoice-search.service';
import { GetSrvSharedService } from '../../../../shared//services/get/get-srv-global/get-srv-shared.service';

@Component({
    selector: 'app-sub-inv-item-dialog',
    styleUrls: ['./sub-inv-item-dialog.component.css'],
    templateUrl: './sub-inv-item-dialog.component.html'
})

export class SubInvItemDialogComponent implements OnInit {
    headerID: string;
    currentPage: any;
    pageSize: any;

    documentDate : string;
    documentNo : string;
    documentType : string;
    entityGstin : string;
    supplyType : string;

    dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    dataListSelection: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(
        public postInvoice: PostInvoiceSearchService,
        private snackBar: MatSnackBar,
        private getColumnList: GetSrvSharedService,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.headerID = data.title;
        this.documentDate = data.documentDate;
        this.documentNo = data.documentNo;
        this.documentType = data.documentType;
        // if(data.entityGstin == undefined)
        // {
        //     this.entityGstin = data.irnEntityGstin;
        // }
        // else
        // {
        // this.entityGstin = data.entityGstin;
        // }
        
        if(data.supplyType == "IRNOutwards")
        {
            this.supplyType = "Outwards";
            this.entityGstin = data.irnEntityGstin;
        }
        else
        {
        this.supplyType = data.supplyType;
        this.entityGstin = data.entityGstin
        }
    }

    ngOnInit() {
        this.isTableMultiSelect.next(false);
        this.getDisplayedColumnList('Doc_Out_LineItem');
    }

    getDisplayedColumnList(tableType: string) {
        this.getColumnList.getColumnList(tableType).subscribe
            (
                data => {
                    if ('200'.localeCompare(data.status) === 0) {
                        this.columnList.next(data.data);
                    }
                },
                error => {
                    let errorStatus;
                    let errorMessage;
                    if (error.error.message !== undefined) {
                        errorStatus = error.error.status;
                        errorMessage = error.error.message.substring(0, 90);
                    } else {
                        errorStatus = error.status;
                        errorMessage = error.error;
                    }
                    this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                        duration: GlobalSrvConstantsService.SNACKBARDURATION,
                        panelClass: ['danger-snackbar']
                    });
                }
            );
    }

    getLineItemData(currentPage, pageSize) {
        const formData = new FormData();
        formData.append('InvoiceHeaderID', this.headerID);
        formData.append('PageSize', pageSize);
        formData.append('CurrentPage', currentPage);
        formData.append('SupplyType',this.supplyType);
        this.postInvoice.postInvoiceLineItemData(formData).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.dataList.next(data.data.dataList);
                    this.dataListPageCount.next(data.data.count);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    receiveEInvoicePageEvent(event: any) {
        this.currentPage = event.currentPage;
        this.pageSize = event.pageSize;
        this.getLineItemData(this.currentPage, this.pageSize);
    }

    receiveDataSelection( event: any) {

    }

}
