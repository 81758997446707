import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlobalApiService } from './shared/services/global/global-api/global-api.service';
import { Roles } from './shared/Constants';
import { I } from '@angular/cdk/keycodes';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private oauthService: OAuthService, private router: Router, private globalsrv: GlobalApiService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.oauthService.hasValidIdToken()) {
            //this.register_tab_GUID();
            let org = JSON.parse(sessionStorage.getItem('id_token_claims_obj')) as any;
            let value = [];
            value.push(org.iss);
            localStorage.setItem("environment", JSON.stringify(value));
            var login = localStorage.getItem("login");
            var orgSwitch = localStorage.getItem("orgSwitch");    
            var env = localStorage.getItem("environment");
            var currentOrg = GlobalApiService.getCurrentOrganizationID();
            if(env.includes(org.iss) && (login == "false" || (currentOrg !='' && orgSwitch !=null && orgSwitch != currentOrg))){
            sessionStorage.clear();
            localStorage.removeItem("orgSwitch");
            this.router.navigate(['/login']);
            return false;
            }
            switch (state.url) {
                case '/upload':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/inv':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/ei/uid':
                    return this.checkIsRolesExist(Roles.EInvoice);
                case '/ei/conseinv':
                    return this.checkIsRolesExist(Roles.EInvoice);
              
                case '/users':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/user':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/masters/Buyer':
                    return this.checkIsRolesExist(Roles.EInvoice);
                case '/masters/Supplier':
                    return this.checkIsRolesExist(Roles.EInvoice);
                case '/masters/Items':
                    return this.checkIsRolesExist(Roles.EInvoice);
                case '/admin/organization':
                    return this.checkIsRolesExist(Roles.SuperAdmin);
                case '/admin/pat':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/admin/users':
                    return this.checkIsRolesExist(Roles.Admin);
                case '/upload':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/ei/documentSearch':
                    return this.checkIsRolesExist(Roles.DataUpload);
                case '/reports/reports':
                    return this.checkIsRolesExist(Roles.Reports);


                default:
                    if (state.url.includes('/user?Id')) {
                        return this.checkIsRolesExist(Roles.Admin);
                    }
                    return true;
            }
        }

        this.router.navigate(['/login']);

        return false;
    }
    checkIsRolesExist(roles: string) {
        if (roles != "" && roles != undefined) {

            var rolesRequired = roles.split('!');
            let count = 0;
            rolesRequired.forEach((role) => {
                if (this.globalsrv.userroles.findIndex(x => x == role) != -1) {
                    count = count + 1;
                }
            })
            return rolesRequired.length == count
        }
    }
}
