import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config/app-config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GlobalApiService } from '../global/global-api/global-api.service';
import { PrepareReturnModel, GSTR1SummaryResponseModel, GSTR1SummaryDownloadViewModel, GSTR1RemoveTransactionalDataViewModel, PrepareReturnsRequestsViewModel, SectionDetailsModel, PrepareSalesVsIRPModel, Section2XDetailsModel } from './returns-model.service';
import { GetGSTR2ARequetViewModel } from '../../models/global/boew-eway/get-boew-eway-search';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';

@Injectable({
  providedIn: 'root'
})
export class ReturnsService {

  ReceiveGSTR1Summary:BehaviorSubject<GSTR1SummaryResponseModel[]> = new BehaviorSubject(null);
  GetSummary:BehaviorSubject<GSTR1SummaryDownloadViewModel> = new BehaviorSubject(null);
  constructor(private _httpClient:HttpClient) { }
  ewayBillAPIBaseUrl = AppConfigService.ApiEnvoiceEwaybillServiceUrl;
  gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
  getFilterData(returnType : string): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/gstn/FilterData' + '/' + returnType
    );
}


  GSTR1Summary(PrepareReturns: PrepareReturnModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTR1Summary', PrepareReturns
    );
  }

    PrepareGSTR1Download(PrepareReturns: PrepareReturnModel): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/GSTR1Returns/PrepareGSTR1Download', PrepareReturns, { responseType: 'blob' }
        );
    }
  InsertGSTR1PrepareRequest(PrepareReturns: PrepareReturnModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/InsertGSTR1PrepareRequest', PrepareReturns,
    );
  }
  InsertGSTR1ReportRequest(PrepareReturns: PrepareReturnModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/InsertDownloadGSTR1ReportRequest', PrepareReturns,
    );
  }
  getGSTR1ViewSummary(PrepareReturns: PrepareReturnModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1ViewSummary', PrepareReturns,
    );
  }

  getPrepareGSTR1RequestDetails(prepareReturnsRequestsViewModel : PrepareReturnsRequestsViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetPrepareGSTR1RequestHistory', prepareReturnsRequestsViewModel
    )
  }

  getGSTR1ReportRequestDetails(prepareReturnsRequestsViewModel : PrepareReturnsRequestsViewModel): Observable<any> {
    console.log(GetGSTR2ARequetViewModel);
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1ReportsHistory', prepareReturnsRequestsViewModel
    )
  }

  DownloadEinvoiceReco(PrepareReturns: PrepareReturnModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/DownloadEinvoiceReco', PrepareReturns, { responseType: 'blob' }
    );
  }

  summarydownload(GSTR1SummaryDownload: GSTR1SummaryDownloadViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTR1SummaryPdfDownload', GSTR1SummaryDownload
    );
  }

  removeTransactionalData(gSTR1RemoveTransactionalData: GSTR1RemoveTransactionalDataViewModel): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GSTR1RemoveTransactionData', gSTR1RemoveTransactionalData
    );
  }

  getSectionDetails(sectionRequestsViewModel: SectionDetailsModel): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1DashboardSummary?GSTIN='+ sectionRequestsViewModel.gstin+ '&Period=' +sectionRequestsViewModel.period+'&TransactionType=' +sectionRequestsViewModel.transactionType+'&currentPage=' +sectionRequestsViewModel.currentpage+'&pageSize=' +sectionRequestsViewModel.pageSize+'&Source=' +sectionRequestsViewModel.source+'&ResultType=' +sectionRequestsViewModel.type+'&subType=' +sectionRequestsViewModel.subType);  
  }

  SaveDocDetails(data:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/InsertOrUpdateGSTR1DashboardDetails', data
      );  
  }

  resetDocDetails(sectionRequestsViewModel: SectionDetailsModel): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1DashboardSummary?GSTIN='+ sectionRequestsViewModel.gstin+ '&Period=' +sectionRequestsViewModel.period+'&TransactionType=' +sectionRequestsViewModel.transactionType+'&currentPage=' +sectionRequestsViewModel.currentpage+'&pageSize=' +sectionRequestsViewModel.pageSize+'&Source=' +sectionRequestsViewModel.source+'&ResultType=' +sectionRequestsViewModel.type+'&prepareData=' +sectionRequestsViewModel.prepareData); 
  }
 
  getTansactionType(GSTIN,Period,source,type): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR1DashboardSummary?GSTIN='+ GSTIN+ '&Period=' +Period+ '&Source=' +source+ '&ResultType=' +type);    
  }

  download(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR9/DownloadViewandTrackreturnsTemplate', { responseType: 'blob' }
    );
  }
  
  UploadTrackReturnsFile(formdata:any): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR9/UploadTrackReturnsFile',formdata);    
  }

  UploadHSNSummaryFile(formdata:any,overwrite:string,gstin:string,period:string,type:string): Observable<any> {
    return this._httpClient.post(
      this.gstrServiceBaseUrl + '/GSTR1Returns/UploadHSNSummaryFile?GSTIN='+ gstin+ '&Period=' +period + '&type=' + type+ '&overwrite=' + overwrite,formdata);    
  }

  getUqcandRate(): Observable<any> {
    return this._httpClient.get(
      this.gstrServiceBaseUrl + '/GSTR1Returns/UQCandRateData'
    );
}

SaveDetails(data:any,type:string): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/InsertOrUpdateGSTR1Dashboard' ,data
    ); 
}

downloadHSNData(data:any): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/DownloadGSTR1Data',data); 
}
InsertSalesVsIRPRequest(PrepareReturns: PrepareSalesVsIRPModel): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/InsertSalesVsIRPPrepareRequest', PrepareReturns,
  );
}

getVendorGSTINs(GSTIN, Period, StartDate,EndDate, ReportName): Observable<any> {
  return this._httpClient.get(this.gstrServiceBaseUrl + '/GSTR1Returns/GetListOfCTIN?GSTIN='+ GSTIN + '&Period='+ Period + '&StartDate='+ StartDate + '&EndDate='+EndDate+ '&ReportName='+ReportName);   
}

get2XSectionDetails(sectionRequestsViewModel: Section2XDetailsModel): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR2XDashboardSummary?GSTIN='+ sectionRequestsViewModel.gstin+ '&Period=' +sectionRequestsViewModel.period+'&TransactionType=' +sectionRequestsViewModel.transactionType+'&currentPage=' +sectionRequestsViewModel.currentpage+'&pageSize=' +sectionRequestsViewModel.pageSize+'&ResultType=' +sectionRequestsViewModel.type);  
}
get2XTansactionType(GSTIN,Period,type): Observable<any> {
  return this._httpClient.get(
    this.gstrServiceBaseUrl + '/GSTR1Returns/GetGSTR2XDashboardSummary?GSTIN='+ GSTIN+ '&Period=' +Period+ '&ResultType=' +type);    
}

update2XTansactionType(data:any): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/UpdateGSTR2XDashboardDetails', data
    );  
}
generate2xPDF(GSTR1SummaryDownload: any): Observable<any> {
  return this._httpClient.post(
    this.gstrServiceBaseUrl + '/GSTR1Returns/ReturnSummaryPdfDownload', GSTR1SummaryDownload
  );
}
}


