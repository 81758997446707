import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { FileHistory, ErrorFileHistory,DeleteFileHistory } from '../shared/models/get/get-md-beow-upload/get-file-history';
import { GetUploadFileService } from '../shared/services/get/get-srv-boew-upload/get-upload-file.service';
import { GlobalSrvConstantsService } from '../shared/services/global/global-constants/global-srv-constants.service';
import { GetSrvSharedService } from '../shared/services/get/get-srv-global/get-srv-shared.service';
import { DataColumnList } from '../shared/models/get/get-md-global/get-column-list';
import { GlobalApiService } from '../shared/services/global/global-api/global-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Dialog, DialogTypes } from 'src/app/shared/Constants';
import { BoConfirmationDialogComponent } from 'src/app/shared/bo-confirmation-dialog/bo-confirmation-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-boew-upload',
    styleUrls: ['./boew-upload.component.css'],
    templateUrl: './boew-upload.component.html',
    providers: [DatePipe]
})
export class BoewUploadComponent implements OnInit, OnDestroy {
    fileuploaded = '0';
    waitingFileListResponse = false;
    waitingErrorFileListResponse = false;
    constructor(private dialog: MatDialog, private globalService: GlobalApiService,private _getUplaodFile: GetUploadFileService, private snackBar: MatSnackBar, private getColumnList: GetSrvSharedService,private datePipe:DatePipe) { }

    fileData: FileHistory[];
    errorFileData: ErrorFileHistory[];
    deleteFileData: DeleteFileHistory[];
    id: any;
    fileHistory: BehaviorSubject<FileHistory[]> = new BehaviorSubject<FileHistory[]>(this.fileData);
    errorFileHistory: BehaviorSubject<ErrorFileHistory[]> = new BehaviorSubject<ErrorFileHistory[]>(this.errorFileData);
    deleteFileHistory: BehaviorSubject<DeleteFileHistory[]> = new BehaviorSubject<DeleteFileHistory[]>(this.deleteFileData);
    allColumnList: BehaviorSubject<DataColumnList[]> = new BehaviorSubject<DataColumnList[]>(null);
    errorDocumentColumnList: BehaviorSubject<DataColumnList[]> = new BehaviorSubject<DataColumnList[]>(null);
    deleteDocumentColumnList: BehaviorSubject<DataColumnList[]> = new BehaviorSubject<DataColumnList[]>(null);
    pageCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    errorPageCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    deletePageCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    hideDownloadButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    errorDocumentNumber: string;
    pageSize = 5;
    currentPage = 1;
    waitForResponse = true ;
    filterPagination = false;
    isAdmin = false;
    documentNumber: string='';
    dateSelected:any = '';
    filterName : string ;
    ngOnInit() {
        //this.getFileHistory(5, 1);
        this.validateUser();
        //this.getTableColumnList();
        //this.getErrorColumnList();
        //this.getDeleteColumnList();
        //this.getDeleteFileHistory(5,1);
        this.id = setInterval(() => {
            if(this.waitForResponse)
            {
                this.getFileHistory(this.pageSize, this.currentPage,this.filterName,this.documentNumber,this.dateSelected);
            }
        }, 40000);
    }

    receiveFileUploadInput($event) {
        this.fileuploaded = $event;
        if (this.fileuploaded === '1') {
            this.getFileHistory(5, 1,this.filterName,this.documentNumber,this.dateSelected);
        }
    }

    receivePageEvent($event) {
        this.pageSize = $event.pageSize;
        this.currentPage = $event.currentPage;
        this.filterName = $event.filterValue;
        this.getFileHistory($event.pageSize, $event.currentPage, $event.filterValue,this.documentNumber,this.dateSelected);
    }

    receiveErrorPageEvent($event) {
        this.pageSize = $event.pageSize;
        this.currentPage = $event.currentPage;
        //this.getErrorColumnList();
        if (this.errorDocumentNumber)
            this.getErrorFileHistory(this.pageSize, this.currentPage, this.errorDocumentNumber);
    }

    receiveDeletePageEvent($event) {
        this.pageSize = $event.pageSize;
        this.currentPage = $event.currentPage;
       // this.getDeleteFileHistory(this.pageSize, this.currentPage);  
    }
    resetGrid(){
        this.errorFileData = [];
        this.errorFileHistory.next(this.errorFileData);
    }
    onRemoveFileHistory($event) {
       let dialogTemplate = new Dialog();
        dialogTemplate.Type = DialogTypes.Confirmation;
        dialogTemplate.Message = "Are you sure want to delete?";

        const dialogRef = this.dialog.open(BoConfirmationDialogComponent, {
            data: dialogTemplate
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._getUplaodFile.removeFileFromDataUpload($event).subscribe((data: any) => {
                    this.getFileHistory(this.pageSize, this.currentPage,null,this.documentNumber,this.dateSelected);
                    this.getDeleteFileHistory(5,1);
                });

            }
        });
    }

    searchErrorDocuments($event) {
        this.errorDocumentNumber = $event;
        this.pageSize = 5;
        this.currentPage = 1;
        this.getErrorFileHistory(this.pageSize, this.currentPage, this.errorDocumentNumber);
    }

    getFileHistory(pageSize: any, currentPage: any,filterValue:any,fileName:any,date:any) {
        this.waitingFileListResponse = true;
        this.waitForResponse = false;
        if(date == null || date == ''){
            date = '';
        }
        if(fileName == null || fileName == ''){
            fileName = '';
        }
        if(filterValue == undefined){
            filterValue = null;
        }
        else{
            if( filterValue.colname == "uploadedOn"){
                try{
                    var dateString = filterValue.value;
                    var dateParts = dateString.split("/");
                    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
                    filterValue.value =  this.datePipe.transform(dateObject,"yyyy-MM-dd");
                }
                catch{
                    filterValue.value =   filterValue.value;
                }
                date =  filterValue.value ;
                filterValue = null;
            }
          //  filterValue.value = filterValue.colname == "uploadedOn" ?  this.datePipe.transform(new Date(filterValue.value),"yyyy-MM-dd") : filterValue.value; 
        }
        this._getUplaodFile.getFileHistory(pageSize, currentPage,filterValue,fileName,date).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    if(fileName != '' || date != ''){
                        this.filterPagination = true;
                    }
                    else{
                        this.filterPagination =false;
                    }
                    this.fileData = data.data.dataList;
                    this.pageCount.next(data.data.count);
                    this.allColumnList.next(data.data.allColumnsList);
                    this.fileHistory.next(this.fileData);
                    this.waitForResponse = true;
                    setTimeout(() => { this.waitingFileListResponse = false; }, 1000);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
               this.waitForResponse = true;
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
                setTimeout(() => { this.waitingFileListResponse = false; }, 1000);
            }
        );
    }

    getErrorFileHistory(pageSize: any, currentPage: any, documentDetails: any) {
        this._getUplaodFile.getErrorFileHistory(pageSize, currentPage, documentDetails.documentNo, documentDetails.supplyType).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    if (data.message != null && data.message != "" && data.message != undefined) {
                        let msg = data.message.split("|");
                        if (msg[1] == "True") {
                            this.hideDownloadButton.next(true);
                        }
                        if (msg[0] == "False") {
                            this.snackBar.open('No Matching Templates Found', 'OK', {
                                duration: GlobalSrvConstantsService.SNACKBARDURATION,
                                panelClass: ['alert-snackbar']
                            });
                            return;
                        }
                    }
                    this.errorFileData = data.data.dataList;
                    this.errorPageCount.next(data.data.count);
                    this.errorDocumentColumnList.next(data.data.allColumnsList);
                    this.errorFileHistory.next(this.errorFileData);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    getDeleteFileHistory(pageSize: any, currentPage: any) {
        this._getUplaodFile.getDeleteFileHistory(pageSize, currentPage).subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    this.deleteFileData = data.data.dataList;
                    this.deletePageCount.next(data.data.count);
                    this.deleteFileHistory.next(this.deleteFileData);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }

    validateUser() {
         this.globalService.validateUser().subscribe(
            data => {
                this.isAdmin = data.data;
                this.getTableColumnList();
        });  
    }

    getTableColumnList() {
        this.getColumnList.getColumnList('Document_Upload').subscribe(
            data => {
                if ('200'.localeCompare(data.status) === 0) {
                    // if (this.isAdmin == true) {
                    //     data.data.push({
                    //         columnName: 'Document_Upload_Delete_GSTR1Template',
                    //         alias: 'Action',
                    //         isDefault: true,
                    //     });
                    // }
                    this.allColumnList.next(data.data);
                }
            },
            error => {
                let errorStatus;
                let errorMessage;
                if (error.error.message !== undefined) {
                    errorStatus = error.error.status;
                    errorMessage = error.error.message.substring(0, 90);
                } else {
                    errorStatus = error.status;
                    errorMessage = error.error;
                }
                this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
                    duration: GlobalSrvConstantsService.SNACKBARDURATION,
                    panelClass: ['danger-snackbar']
                });
            }
        );
    }
    // getErrorColumnList() {
    //     this.getColumnList.getColumnList('Error_Document').subscribe(
    //         data => {
    //             if ('200'.localeCompare(data.status) === 0) {
    //                 this.errorDocumentColumnList.next(data.data);
    //             }
    //         },
    //         error => {
    //             let errorStatus;
    //             let errorMessage;
    //             if (error.error.message !== undefined) {
    //                 errorStatus = error.error.status;
    //                 errorMessage = error.error.message.substring(0, 90);
    //             } else {
    //                 errorStatus = error.status;
    //                 errorMessage = error.error;
    //             }
    //             this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
    //                 duration: GlobalSrvConstantsService.SNACKBARDURATION,
    //                 panelClass: ['danger-snackbar']
    //             });
    //         }
    //     );
    // }

    // getDeleteColumnList() {
    //     this.getColumnList.getColumnList('DeleteUploadDocument').subscribe(
    //         data => {
    //             if ('200'.localeCompare(data.status) === 0) {
    //                 this.deleteDocumentColumnList.next(data.data);
    //             }
    //         },
    //         error => {
    //             let errorStatus;
    //             let errorMessage;
    //             if (error.error.message !== undefined) {
    //                 errorStatus = error.error.status;
    //                 errorMessage = error.error.message.substring(0, 90);
    //             } else {
    //                 errorStatus = error.status;
    //                 errorMessage = error.error;
    //             }
    //             this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
    //                 duration: GlobalSrvConstantsService.SNACKBARDURATION,
    //                 panelClass: ['danger-snackbar']
    //             });
    //         }
    //     );
    // }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        if (this.id) {
            clearInterval(this.id);
        }
    }

    searchFileDocuments($event){
        this.documentNumber = $event.documentNumber;
        this.dateSelected = $event.dateSelected;
        this.pageSize = 5;
        this.currentPage = 1;             
        this.getFileHistory(this.pageSize, this.currentPage,this.filterName,this.documentNumber,this.dateSelected);
    }
    
    refresh(){   
        this.getFileHistory(this.pageSize, this.currentPage,this.filterName,this.documentNumber,this.dateSelected);
    }
}
