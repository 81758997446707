import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { BoLogOutComponent } from './bo-log-out/bo-log-out.component';
import { BoLoginComponent } from './bo-login/bo-login.component';
import { BoewUploadComponent } from './boew-upload/boew-upload.component';
import { UnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
import { NotificationDetailReportComponent } from './notification-detail-report/notification-detail-report.component';

const routes: Routes = [
    { path: '', component: AppComponent, canActivate: [AuthGuard] },
    {path:'upload',component:BoewUploadComponent,canActivate:[AuthGuard]},
    {path:'logout',component:BoLogOutComponent},
    { path: 'login', component: BoLoginComponent },
    { path: 'ei', loadChildren: () => import('./einvoice/einvoice.module').then(m => m.EinvoiceModule),canActivate: [AuthGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),canActivate: [AuthGuard] },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate: [AuthGuard] },
    { path: 'masters', loadChildren: () => import('./Masters/masters.module').then(m => m.MastersModule),canActivate: [AuthGuard] },
    { path: 'unauthorized',component:UnauthorizedComponent,canActivate:[AuthGuard]},
    { path: 'notification', component: NotificationDetailReportComponent ,canActivate: [AuthGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
