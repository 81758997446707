import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoLoginComponent } from './bo-login/bo-login.component';
import { BoNavComponent } from './bo-nav/bo-nav.component';
import { BoewUploadComponent } from './boew-upload/boew-upload.component';
import { SubFileUploadFormComponent } from './boew-upload/sub-components/sub-file-upload-form/sub-file-upload-form.component';
import { SharedModuleModule } from './shared/shared-module.module';
import { SubFileUploadGridComponent } from './boew-upload/sub-components/sub-file-upload-grid/sub-file-upload-grid.component';
import { BoLogOutComponent } from './bo-log-out/bo-log-out.component';

import { UserIdleModule } from 'angular-user-idle';
import { IdleTimeOutSettings } from './shared/Constants';
import { NotificationDetailReportComponent } from './notification-detail-report/notification-detail-report.component';


@NgModule({
    bootstrap: [BoNavComponent],
    declarations: [
        AppComponent,
        BoNavComponent,
        BoLoginComponent,
        BoewUploadComponent, 
        SubFileUploadFormComponent,
        SubFileUploadGridComponent,
        BoLogOutComponent,
        NotificationDetailReportComponent
        
       
    ],
    imports: [
        UserIdleModule.forRoot({ idle: IdleTimeOutSettings.IdleTime, timeout: IdleTimeOutSettings.TimeOutAfterIdleTime }),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModuleModule
    ]
})
export class AppModule { }
