export interface GetBoewEwaySearch {
    entityGSTIN: EntityGSTINSelected[];
    FP: FPSelected[];
    transporterGSTIN: TransporterIdSelected[];
    invoiceNumber: string;
    status: number;
    beginDate: string;
    endDate: string;
    currentPageNo: number;
    pageSize: number;
    documentNumber:string;
}

export interface EntityGSTINSelected {
    EntityGSTIN: string;
}

export interface FPSelected {
    FP: string;
}
export interface TransactionTypeSelected {
    transactionType: string;
}

export interface TransporterIdSelected {
    TransporterID: string;
}

export class EWayDownloadRequest {
    organizationId: Number;
    organizationName:string;
    outwardData: OutwardData[] = [];
    isPdfDownload : boolean;
    typeOfEwayBill : string;
}

export class OutwardData {
    outwardHeaderID: Number;
    fileID: Number;
    ewayBillNumber : string;
}

export class GetGSTR2ARequetViewModel{
    EntityGstin  : EntityGSTINSelected[];
    ReportedPeriod : FPSelected[] ;
    TransactionType : string;
    Currentpage : number;
    PageSize : number;
    ReturnType : string;
    RequestedBy : string;
    isMergeSelected : boolean;
    Gstr1TransactionTypes  : string[];
    Status : number;
    RequestPeriodData :number;
    isSummaryRequired : boolean;
}

export class GetSchedulerRequetViewModel{
    EntityGstin  : EntityGSTINSelected[];
    ReportedPeriod : FPSelected[] ;
    TransactionType : string;
    Currentpage : number;
    PageSize : number;
    ReturnType : string;
    RequestedBy : string;
    isMergeSelected : boolean;
    Gstr1TransactionTypes  : string[];
    OrganizationDetails : string;
    StartDate : string;
    StartTime : string;
}

export class DownloadGSTR2ARequetViewModel{
    EntityGstin  : string;
    ReportedPeriod : string[] ;
    Currentpage : number;
    PageSize : number;
    RequestedBy : string;
}

export class GetGSTR2AReportViewModel{
    RequestedBy : string;
    Currentpage : number;
    PageSize : number;

}

export class GetReconsileGSTR2AReportViewModel extends GetGSTR2AReportViewModel{
    Reportname2 : string;
}


export class InsertReconcileGSTR2BRequetViewModel {
    EntityGstin  : string[];
    VendorGstin  : string[];
    For2B : InsertReconcileGSTR2BModel ;
    ForPR:InsertReconcileGSTR2BModel;
    Currentpage : number;
    PageSize : number;
    TransactionType:string;
    RCMSelection:string;
    ITCEligibleSelection:string;
    ToleranceByTaxableAmt:number;
    ToleranceByTaxAmt:number;
    IgnoreSplChr: boolean;
    validateVendorGSTIN: boolean;
    ToleranceByCessAmt:number;
    VendorGSTINFileID:number;
}

export class InsertReconcileGSTR2BModel {
    ReportedPeriod  : string[];
    StartDate:string;
    EndDate:string;
    PeriodOrYTD:number;
}

export interface Gstr6reconData {
    gstr6reconDataId:number,
    entityGstin: string;
    period: string;
    g2aDocumentNumber: string;
    g2aDocumentDate: string;
    g2aDocumentValue: string;
    g2aTaxablevalue: string;   
    g2aIgst: string;
    g2aSgst: string;   
    g2aCgst: string;
    g2aCess: string;   
    documentType: string;
    vendorGstin: string;
    g2aPlaceOfSupply: string;
    userAction: string;
    subStatus: string;
    isItceligible: boolean;
}

export class PostGGstr6ARecoIds {
    ids:  number[];
    action : string;
}

export class MastersDataModel{
    Code  : string;
    Currentpage : number;
    PageSize : number;
    Type : string;
    RequestedBy : string;
    isMergeSelected : boolean;
    }

    
export class CustomerMasterDataModel{
    CustomerMasterId  : number;
    CustomerCode : string;
    OrganizationId : number;
    Tin : string;
    CustomerName : string;
    SST : string;
    RegistrationType : string;
    RegistrationNumber : string;
    Address0 : string;
    Address1 : string;
    Address2 : string;
    City : string;
    State : string;
    PinCode : string;
    Country : string;
    EmailId : string;
    ContactNumber : string;
    CreatedBy : string;
    CreatedOn : Date;
    }
    
export class SupplierMasterDataModel{
    SupplierId  : number;
    Code : string;
    OrganizationId : number;
    Tin : string;
    Name : string;
    Address0 : string;
    SST : string;
    Address1 : string;
    Address2 : string;
    City : string;
    State : string;
    ZipCode : string;
    Country : string;
    EmailId : string;
    Phone : string;
    CreatedBy : string;
    CreatedOn : Date;
    MSIC:string;
    RegistrationNumber:string;
    RegistrationType:string;
    Description:string;
    TourismTax:string;
    BankAccountNumber:string;
    }
    
export class ItemMasterDataModel{
    ItemMasterId  : number;
    ItemCode : string;
    OrganizationId : number;
    TaxType : string;
    TaxRate : string;
    DetailsOfTaxExemption : string;
    ItemDescription : string;
    Classification : string;
    DescriptionOfProductService : string;
    Measurement : string;
    CreatedBy : number;
    CreatedOn : Date;
    }

export class POMasterDataModel {
    MasterPoid: number;
    Ponumber: string;
    OrganizationId: number;
    Podate: Date;
    CustomerName: string;
    PovalueExcludingGst: number;
    GstamountUnderPo: number;
    Remarks: string;
    NatureOfSupply: string;
    DeletedBy: string;
    DeletedOn: Date;
    IsActive: boolean;
    CreatedBy: string;
    CreatedOn: Date;
    POValue: number;
}
export class QrcInvoiceDownloadModel{
    FileId:  number[];
    RefFileId:  number[];
    Type: string;

}
export class QrcInvoiceReportGenerationModel{
    StartDate :string
    EndDate : string;
    Type: string;
}

export class TaxComaprisonModel{
    EntityGstin  : string[];
    ReportedPeriod : string[] ;
    RequestedBy : string;
    ReturnType : string;
}
export class DownloadTaxComaprisonModel{
    EntityGstin  : string[];
    ReportedPeriod : string[] ;
    Currentpage : number;
    PageSize : number;
    RequestedBy : string;
    Type : string;
}

export class ForceMatchModel{
    Action : number;
    dataList:InwardHeader[];
    period:string;
    returnType : string;
    remarks : string;
    invoiceStatus:string;
}

export class InwardHeader{
    InwardDocumentHeaderID: any;
    G2ADocument: G2ADocumentModel;
}

export class G2ADocumentModel{
    G2A_TableName : string;
    G2A_HeaderID : number;
}

export class AutoRecoDashboard {
    EntityGstin  : string[];
    VendorGstin  : string[];
    G2AStartDate : string;
    G2AReportedPeriod : string[];
    PRReportedPeriod : string[];
    G2AEndDate : string;
    PRStartDate : string;
    PREndDate : string;
    TransactionType:string;
    ToleranceByTaxableAmt:number;
    ToleranceByTaxAmt:number;
}

export class VendorDashboard {
    pR_VendorGSTIN  : string;
    vendorName  : string;
    gstR1FilingStatus : string;
    gstR3BFilingStatus : string;
    exactTotalTax : number;
    exactPercentage : number;
    misMatchValue : number;
    misMatchPercentage : number;
    proPercentage:number;
    probValue:number;
    parPercentage:number;
    parValue:number;crossPercentage:number;
    forceTotalTax : number;
    forcePercentage : number;
    crossValue:number;InG2APercentage:number;
    InG2AValue:number;
    InPRPercentage:number;
    InPRValue:number;
}
export class TaxNoticeRequest {
    
    responseDesc: string;
    responseDate: string;
    responseFilingDate: string;
    justificationForDelay: string;
    responseNo: string;
    responsetype: string;
    taxNoticeId:number;
    AdditionaltaxNoticeId:number;
    typeOfNotice:string;
    
}