
export function getEInvoiceCopyFileName(documentNumber: string): string {
  return documentNumber + '.pdf';
}
export function getEInvoiceXMLCopyFileName(documentNumber: string): string {
  return documentNumber + '.xml';
}

export function getEwayBillCopyFileName(ewayBillNumber: string): string {
  return 'EwayBill_' + ewayBillNumber + '.pdf';
}

export function isValidEmailIDs(emailIDs: string[]): boolean {
  const validator = require('validator');
  const isValid =  true;

  for (const emailID of emailIDs) {
    const val = validator.isEmail(emailID);
    if (!validator.isEmail(emailID)) {
      return false;
    }
  }

  return isValid;
}

export function isStringNullOrEmpty(str: string): boolean {
  return (!str || 0 === str.length);
}

export function getEInvoiceEmailSubject(documentNumber: string, entityLegalName: string, customerLegalName: string, vendorPoreferenceNo: string) : string{

  let eInvoiceEmailSubject =  entityLegalName + ' | ' + documentNumber + ' | ' +  customerLegalName;

  if (vendorPoreferenceNo) {
      eInvoiceEmailSubject += ' | ' + vendorPoreferenceNo;
  }

  return eInvoiceEmailSubject;
}
