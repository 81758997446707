import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { DataColumnList } from 'src/app/shared/models/get/get-md-global/get-column-list';
import { GlobalApiService } from '../shared/services/global/global-api/global-api.service';
import { GetSrvSharedService } from '../shared/services/get/get-srv-global/get-srv-shared.service';
import { GlobalSrvConstantsService } from '../shared/services/global/global-constants/global-srv-constants.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notification-detail-report',
  templateUrl: './notification-detail-report.component.html',
  styleUrls: ['./notification-detail-report.component.css'],
  providers: [DatePipe]
})
export class NotificationDetailReportComponent implements OnInit {
  pageSize: number = 5;
  currentPage: number = 1;
  listOfNotificationStatus: string[] = ["pending", "batched", "delivered", "error"];
  listOfChannel: string[] = ["email", "push"];
  dataColumns: DataColumnList[] = [];
  dataList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  columnList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  dataListPageCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isTableMultiSelect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  fromDate: string;
  toDate: string;
  notificationSelected: string;
  ChannelSelected: string;
  selectedFromDate: string;
  selectedToDate: string;
  constructor(public datepipe: DatePipe, private globalApiService: GlobalApiService, private getColumnList: GetSrvSharedService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getDisplayedColumnList("GetNotification");
    this.isTableMultiSelect.next(false);
    this.dataList.next(null);
  }

  getDisplayedColumnList(tableType: string) {
    this.getColumnList.getColumnList(tableType).subscribe
      (
        data => {
          if ('200'.localeCompare(data.status) === 0) {
            this.dataColumns = data.data;
            this.columnList.next(data.data);
          }
        },
        error => {
          let errorStatus;
          let errorMessage;
          if (error.error.message !== undefined) {
            errorStatus = error.error.status;
            errorMessage = error.error.message.substring(0, 90);
          } else {
            errorStatus = error.status;
            errorMessage = error.error;
          }
          this.snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          });
        }
      );
  }

  receivePageEvent(event: any) {
    this.currentPage = event.currentPage;
    this.pageSize = event.pageSize;
    this.refresh();
    //this.getReportRequestedDetails(this.currentPage, this.pageSize)
  }

  getReportRequestedDetails(currentPage, pageSize) {
    this.globalApiService.getNotificationRequestDetails(currentPage, pageSize).subscribe(
      data => {
        if ('200'.localeCompare(data.status) === 0) {
          this.dataList.next(data.data.dataList);
          this.dataListPageCount.next(data.data.count);
        }
      }
    )
  }

  refresh() {

    if (this.fromDate == null || this.fromDate == "" || this.fromDate == undefined) {
      return;
    }

    if (this.toDate == null || this.toDate == "" || this.toDate == undefined) {
      return;
    }

    if (this.notificationSelected == null || this.notificationSelected == "" || this.notificationSelected == undefined) {
      return;
    }

    if (this.ChannelSelected == null || this.ChannelSelected == "" || this.ChannelSelected == undefined) {
      return;
    }

    this.selectedFromDate = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.selectedToDate = this.datepipe.transform(this.toDate, 'yyyy-MM-dd');

    let postNotificationRequest = {
      DateFrom: this.selectedFromDate, DateTo: this.selectedToDate , Type: "2", language: "en",
      Status: this.notificationSelected, Channel: this.ChannelSelected, PageNo: 100, PageSize: 100
    };

    this.globalApiService.postNotificationDetails(postNotificationRequest).subscribe(
      data => {
        if ('200'.localeCompare(data.status) === 0) {
          this.dataList.next(data.data.dataList);
          this.dataListPageCount.next(data.data.dataList[0].metadatatotalcount);
        }
      }
    )
  }

  submit() {

    if (this.fromDate == null || this.fromDate == "" || this.fromDate == undefined) {
      this.snackBar.open('Please Select Start Date', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }

    if (this.toDate == null || this.toDate == "" || this.toDate == undefined) {
      this.snackBar.open('Please Select End Date', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }

    if (this.notificationSelected == null || this.notificationSelected == "" || this.notificationSelected == undefined) {
      this.snackBar.open('Please Select Status', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }

    if (this.ChannelSelected == null || this.ChannelSelected == "" || this.ChannelSelected == undefined) {
      this.snackBar.open('Please Select Channel', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return;
    }

    this.selectedFromDate = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.selectedToDate = this.datepipe.transform(this.toDate, 'yyyy-MM-dd');

    let postNotificationRequest = {
      DateFrom: this.selectedFromDate, DateTo: this.selectedToDate , Type: "2", language: "en",
      Status: this.notificationSelected, Channel: this.ChannelSelected, PageNo: 100, PageSize: 100
    };

    this.globalApiService.postNotificationDetails(postNotificationRequest).subscribe(
      data => {
        if ('200'.localeCompare(data.status) === 0) {
          this.dataList.next(data.data.dataList);
          this.dataListPageCount.next(data.data.dataList[0].metadatatotalcount);
        }
       // this.refresh();
      }
    )
  }
}
