import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PostFileUploadService {
    public static GLOBALREPORTURL = environment.reportServiceURL;  
    baseUrl = GlobalApiService.GLOBALAPIURL;
    oganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    constructor(private _httpClient: HttpClient) { }
    gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
    returnServiceBaseUrl= AppConfigService.ReturnServiceUrl;

    // Parameter API
    postFileData(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/inv/upload', FileData);
    }

    postFileDatatoGSTR(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/BoltOnDocument/gstr/upload', FileData);
    }
    postSummaryDatatoGSTR(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/BoltOnDocument/gstr/summaryupload', FileData);
    }

    postFileDatatoReturnService(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.returnServiceBaseUrl + '/api/returns/upload', FileData);
    }

    postFileData_gstinValidation(FileData: any): Observable<any> {
        return this._httpClient.post(
            GlobalApiService.GLOBALREPORTURL + '/gstn/upload', FileData);
    }
    postReconData(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/gstr2a/recon/upload', FileData);
    }
    checkeInvoiceAPIDetails(): Observable<any> {
        return this._httpClient.get(`${GlobalApiService.GLOBALREPORTURL}/gstn/CheckeInvoiceAPIDetails`);
      }

      geteBulkUploadAPIDetails(): Observable<any> {
        return this._httpClient.get(`${GlobalApiService.GLOBALREPORTURL}/gstn/CheckeGSTINValidationBulkAPIDetails`);
      }

      postFileData_jsontoexcel(FileData: any): Observable<any> {
        return this._httpClient.post(
            GlobalApiService.GLOBALREPORTURL + '/jsontoexcel/upload', FileData);
    }

    getJsonToExcelRequestDetails(pageSize,currentPage): Observable<any> {
        return this._httpClient.get(GlobalApiService.GLOBALREPORTURL + '/jsontoexcel/getRequestsData?currentPage='+ currentPage + '&pageSize='+pageSize)
      }
      postPDFDatatoGSTR(FileData: any): Observable<any> {
        return this._httpClient.post(
            this.gstrServiceBaseUrl + '/BoltOnDocument/gstr/refundupload', FileData);
    }
}
